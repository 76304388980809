// Load without config to check is worker logic changed
// eslint-disable-next-line import/no-webpack-loader-syntax
import boSharedWorkerUrl from 'worker-plugin/loader?name=boshw!~/workers/bo-sharedworker.js'
// eslint-disable-next-line import/no-webpack-loader-syntax
import boWorkerUrl from 'worker-plugin/loader?name=bow!~/workers/bo-worker.js'

import channelHandler from '~/mixins/packages/Shared/Client/handlers/echo/channel-handler'
import updateHandler from '~/mixins/packages/Shared/Client/handlers/update-handler'
import { isWindow } from '~/mixins/helpers'

export default (ctx, inject) => {
  function sharedMessage (message) {
    ctx.store.dispatch('shared/sendMessage', message)
  }

  process.env.APP_DEBUG = ctx.$config.worker?.APP_DEBUG
  process.env.WORKER_HEART_BEAT_MS = parseInt(ctx.$config.worker?.WORKER_HEART_BEAT_MS)
  process.env.WORKER_DEACTIVATE_ATTEMPTS_COUNT = parseInt(ctx.$config.worker?.WORKER_DEACTIVATE_ATTEMPTS_COUNT)
  process.env.WORKER_REMOVE_DISCINNECTED_INTERVAL_S = parseInt(ctx.$config.worker?.WORKER_REMOVE_DISCINNECTED_INTERVAL_S)
  process.env.ENABLE_RECORD_LOCKS = ctx.$config.worker?.ENABLE_RECORD_LOCKS

  inject('worker', {
    createBoWorker () {
      let worker
      if (!ctx.store.state.shared.isWorkerUnavailable) {
        if (typeof SharedWorker !== 'undefined') {
          worker = new SharedWorker('~/workers/bo-sharedworker.js', {
            type: 'module',
            name: 'bo-shw'
          })
        } else if (typeof Worker !== 'undefined') {
          worker = new Worker('~/workers/bo-worker.js', {
            type: 'module',
            name: 'bo-w'
          })
        }
      }
      return worker
    }
  })

  inject('repo', {
    // Method to lock repo item
    lockItem (repo, id) {
      if (ctx.$auth.loggedIn && ctx.$config.recordLocks) {
        try {
          // Current user cross-tab sync - share locked item
          sharedMessage(
            {
              cmd: 'lock',
              options: {
                action: 'setLocked',
                value: {
                  repo,
                  id: parseInt(id),
                  by: parseInt(ctx.$auth.user.userId),
                  email: ctx.$auth.user.email
                }
              }
            }
          )
        } catch (error) {
          throw new Error('Lock Item Error:', error)
        }
      }
    },
    // Method to unlock repo item
    unlockItem (repo, id) {
      if (ctx.$auth.loggedIn && ctx.$config.recordLocks) {
        try {
          // Current user cross-tab sync - share locked items
          sharedMessage(
            {
              cmd: 'lock',
              options: {
                action: 'removeLocked',
                value: {
                  repo,
                  id: parseInt(id),
                  by: parseInt(ctx.$auth.user.userId)
                }
              }
            }
          )
        } catch (error) {
          throw new Error('Unlock Item Error:', error)
        }
      }
    },
    // Method to lock my repo item
    lockMyItem (formName, repo, id) {
      if (ctx.$auth.loggedIn && ctx.$config.recordLocks) {
        try {
          // Current user cross-tab sync
          sharedMessage(
            {
              cmd: 'lock',
              options: {
                action: 'setLockedMy',
                value: {
                  formName,
                  repo,
                  id: parseInt(id),
                  by: parseInt(ctx.$auth.user.userId),
                  email: ctx.$auth.user.email
                }
              }
            }
          )
        } catch (error) {
          throw new Error('Lock My Item Error:', error)
        }
      }
    },
    // Method to unlock my repo item
    unlockMyItem (formName, repo, id) {
      if (ctx.$auth.loggedIn && ctx.$config.recordLocks) {
        try {
          // Current user cross-tab sync
          sharedMessage(
            {
              cmd: 'lock',
              options: {
                action: 'removeLockedMy',
                value: {
                  formName,
                  repo,
                  id: parseInt(id),
                  by: parseInt(ctx.$auth.user.userId)
                }
              }
            }
          )
        } catch (error) {
          throw new Error('Unlock My Item Error:', error)
        }
      }
    },
    // Method to force unlock repo item
    forceUnlockItem (formName, repo, id) {
      if (ctx.$auth.loggedIn && ctx.$config.recordLocks) {
        try {
          const isLockedByOtherFn = ctx.store.getters['shared/isLockedBy']
          const isLockedByOther = isLockedByOtherFn(repo, id)
          if (isLockedByOther) {
            sharedMessage({
              cmd: 'whisp',
              options: {
                channel: 'bo',
                action: 'forceUnlockItem',
                message: {
                  formName,
                  repo,
                  id,
                  by: ctx.$auth.user.userId,
                  to: isLockedByOther?.by
                }
              }
            })
          }
        } catch (error) {
          throw new Error('Force Unlock Item Error:', error)
        }
      }
    },
    // Method to notify force unlocked repo item
    forceUnlockItemSuccess (repo, id, to) {
      if (ctx.$auth.loggedIn && ctx.$config.recordLocks) {
        try {
          // notify other
          sharedMessage({
            cmd: 'whisp',
            options: {
              channel: 'bo',
              action: 'forceUnlockItemSuccess',
              message: {
                repo,
                id: parseInt(id),
                by: parseInt(ctx.$auth.user.userId),
                to: parseInt(to)
              }
            }
          })
          // Show warning
          const user = ctx.store.state.staffUser.users.find(i => i.id === to)
          ctx.store.dispatch('repos/warning', {
            repository: repo,
            message: `Record has been unlocked by ${user ? `${user.email} (User Id: ${user.id})` : `(User Id: ${to})`}`
          })
        } catch (error) {
          throw new Error('Force Unlock Item Success Error:', error)
        }
      }
    },
    // Method to update dirty repo item
    dirtyItem (formKey, id, fieldName, value) {
      if (ctx.$auth.loggedIn) {
        try {
          const chainItem = ctx.store?.state?.repos?.chain?.find((i) => {
            return i.key === `${formKey}.${id}`
          })
          if (chainItem) {
            sharedMessage({
              cmd: 'upd',
              options: {
                action: 'dirtyItem',
                data: {
                  repo: chainItem.repo,
                  id,
                  formKey,
                  fieldName,
                  value,
                  by: ctx.$auth.user.userId
                }
              }
            })
          } else if (formKey === 'CustomerEditForm') {
            // temporary hardcoded...
            sharedMessage({
              cmd: 'upd',
              options: {
                action: 'dirtyItem',
                data: {
                  repo: 'customers',
                  id,
                  formKey,
                  fieldName,
                  value,
                  by: ctx.$auth.user.userId
                }
              }
            })
          }
        } catch (error) {
          throw new Error('Dirty Item Error:', error)
        }
      }
    },
    // Method to update repo item
    applyForm (formKey, id, formData) {
      if (ctx.$auth.loggedIn) {
        try {
          sharedMessage({
            cmd: 'upd',
            options: {
              action: 'applyForm',
              data: {
                formKey,
                id,
                formData
              }
            }
          })
        } catch (error) {
          throw new Error('Dirty Item Error:', error)
        }
      }
    },
    // Method to reload repo item
    reloadItem (formName, repo, id) {
      if (ctx.$auth.loggedIn) {
        try {
          sharedMessage({
            cmd: 'whisp',
            options: {
              channel: 'bo',
              action: 'reloadItem',
              message: {
                formName,
                repo,
                id,
                by: ctx.$auth.user.userId
              }
            }
          })
        } catch (error) {
          throw new Error('Reload Item Error:', error)
        }
      }
    }
  })

  // Storage/SharedWorker/Pusher integration method
  inject('channelHandler', function (responce) {
    channelHandler({
      $auth: ctx.$auth,
      $store: ctx.store,
      $repo: ctx.$repo,
      responce
    })
  })
  inject('sharedUpdateHandler', function (responce) {
    updateHandler({
      $auth: ctx.$auth,
      $store: ctx.store,
      $repo: ctx.$repo,
      responce
    })
  })
}

// On Nuxt app is ready try to connect to Echo Shared Worker
self.onNuxtReadyCbs.push(($vue) => {
  let isInitialised = false
  let workerHash = null
  let type
  if (isWindow) {
    // $vue.$blinkTab.on('New msg', 'warn')

    // if workers is not supported by device - do nothing
    if (
      typeof SharedWorker === 'undefined' &&
      typeof Worker === 'undefined'
    ) {
      $vue.$store.dispatch('shared/unavailable')
    } else {
      // else detect awailable at browser worker type
      if (typeof SharedWorker !== 'undefined') {
        type = 'shared'
      } else if (typeof Worker !== 'undefined') {
        type = 'web'
      }

      // method compare worker code hash and reload it if necessary
      async function manageWorker () {
        try {
          const url = (type === 'shared' ? boSharedWorkerUrl : boWorkerUrl)
          const content = await $vue.$options.$axios.$get(url)
          const newWorkerHash = `${$vue.$config.appVersionId}-${content.hashCode()}`
          if (!workerHash && !isInitialised) {
            $vue.$store.dispatch('shared/hashUpdate', newWorkerHash)
            $vue.$store.dispatch('shared/init')
            workerHash = newWorkerHash
            isInitialised = true
          } else if (isInitialised && workerHash !== newWorkerHash) {
            workerHash = newWorkerHash
            $vue.$store.dispatch('shared/hashUpdate', newWorkerHash)
          }
        } catch (e) {
          $vue.$options.$error('[FETCH WORKER CODE]', e)
        }
      }

      manageWorker()
      // once at hour check is worker updated
      setInterval(() => {
        manageWorker()
      }, 3600000)

      // once at 10 seconds check is page connected to worker
      setInterval(() => {
        const isLost = $vue.$store.getters['shared/checkWorkerLost']()
        if (isLost !== $vue.$store.state.shared.isWorkerLost) {
          $vue.$store.dispatch('shared/lost', isLost)
          if (isLost) {
            $vue.$options.$warn('[Worker Lost]')
          } else {
            $vue.$options.$info('[Worker Connected]')
          }
        }
      }, 10000)
    }
  }
})
