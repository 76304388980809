import { uri } from '../api/uri'
import createRepository from '~/api/repository'
export default (ctx, inject) => {
  // inject the repository in the context (ctx.app.$repository)
  // And in the Vue instances (this.$repository in your components)
  const repositoryWithAxios = createRepository(ctx.$axios)

  // check nested repo
  const isNestedRepo = (repos, name) => {
    const [repoName, nestedRepoName] = name.split('.')
    if (repoName && nestedRepoName && repos[repoName]) {
      const repo = repos[repoName]
      const nested = repo.filter(i => typeof i === 'object')
      if (nested.length && nested[0][nestedRepoName]) {
        return true
      }
    }
    return false
  }
  const hasNestedRepo = (repos, repoName) => {
    if (repoName && repos[repoName]) {
      const repo = repos[repoName]
      const nested = repo.filter(i => typeof i === 'object')
      if (nested.length) {
        return nested
      }
    }
    return false
  }

  if (uri.repository && Object.keys(uri.repository).length) {
    for (const repoName of Object.keys(uri.repository)) {
      const supportedMethods = uri.repository[repoName]
      const newRepo = repositoryWithAxios(repoName, supportedMethods)
      inject(`${repoName}Repository`, newRepo)
      const nested = hasNestedRepo(uri.repository, repoName)
      if (nested.length) {
        for (const nestedRepoName of Object.keys(nested[0])) {
          const supportedNestedMethods = nested[0][nestedRepoName]
          const newNestedRepo = repositoryWithAxios(`${repoName}.${nestedRepoName}`, supportedNestedMethods, repoName)
          inject(`${repoName}.${nestedRepoName}Repository`, newNestedRepo)
        }
      }
    }
  }

  // We can insert more custom features here...
  // inject("userRepository", repositoryWithAxios('/users', ['index', ...]));

  inject('prepareInputs', function ({ repo, method, name }) {
    if (typeof repo === 'undefined') {
      throw new TypeError('Repository \'name\' argument should be specified')
    }
    if (!Object.keys(uri.repository).includes(repo) && !isNestedRepo(uri.repository, repo)) {
      throw new TypeError(`Repository '${repo}' not exists`)
    }
    if (typeof method === 'undefined') {
      throw new TypeError('Repository \'method\' argument should be specified')
    }
    if (typeof ctx[`$${repo}Repository`][method] !== 'function') {
      throw new TypeError(`Repository ${repo} method '${method}' not exists`)
    }
    if (typeof name === 'undefined') {
      name = `${repo}/${method}`
    }
    return { repo, method, name }
  })
}
