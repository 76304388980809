export default (ctx, inject) => {
  inject('confirmWarning', function ({ title, body, okTitle, cancelTitle }) {
    return this.$bvModal.msgBoxConfirm(
      this.$createElement('span', { domProps: { innerHTML: body } }),
      {
        headerBgVariant: 'warning',
        headerTextVariant: 'secondary',
        titleHtml: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        cancelVariant: 'secondary',
        okTitle,
        cancelTitle,
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
        autoFocusButton: 'ok'
      })
  })
  inject('confirmDanger', function ({ title, body, okTitle, cancelTitle }) {
    return this.$bvModal.msgBoxConfirm(
      this.$createElement('span', { domProps: { innerHTML: body } }),
      {
        headerBgVariant: 'danger',
        headerTextVariant: 'secondary',
        titleHtml: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        cancelVariant: 'secondary',
        okTitle,
        cancelTitle,
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
        autoFocusButton: 'ok'
      })
  })
}
