import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import { prepareUrl, uri } from '~/api/uri'
import { log } from '~/mixins/helpers'

export const initState = {
  list: [],
  listPagination: null,
  listType: 'all',
  talksListState: false,
  talksListCount: 0,
  talksListUnread: 0
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  load ({ commit, state }, { talkId, page, loadInBg }) {
    const url = `${prepareUrl(uri.support.messages, { id: talkId })}?order[by]=sent&order[dir]=DESC&page=${typeof page !== 'undefined' ? page : 1}`
    // get data
    return this.$axios.$get(url).then(async (response) => {
      let talk
      const talkIdx = state.list.findIndex(i => i.talkId === talkId)
      if (talkIdx < 0) {
        try {
          const res = await this.$axios.$get(prepareUrl(uri.support.show, { id: talkId }))
          talk = res && res.data
        } catch (e) {
          commit('setError', {
            title: e.message,
            message: e.response && e.response.data && e.response.data.message
          }, { root: true })
        }
      } else {
        if (state.list[talkIdx].state === false) {
          commit('toggle', talkId)
        }
        talk = state.list[talkIdx].talk
      }
      if (talk && Object.keys(talk).length) {
        commit('load', {
          fileExplorerState: false,
          fileExplorerObjectUrl: undefined,
          messages: response.data,
          page: page || 1,
          pagination: response.meta && response.meta.pagination,
          state: !(typeof loadInBg !== 'undefined' && !!loadInBg),
          lift: 0,
          talk,
          talkId
        })
      }
    }).catch((e) => {
      commit('setError', {
        title: e.message,
        message: e.response && e.response.data && e.response.data.message
      }, { root: true })
    })
  },
  update ({ commit, state }, { talkId }) {
    try {
      const talkUrl = prepareUrl(uri.support.show, { id: talkId })
      const msgUrl = `${prepareUrl(uri.support.messages, { id: talkId })}?order[by]=sent&order[dir]=DESC&page=1`
      return Promise.all([
        this.$axios.$get(talkUrl),
        this.$axios.$get(msgUrl)
      ])
        .then(([talkRes, msgRes]) => {
          const talk = talkRes && talkRes.data
          commit('load', {
            fileExplorerState: false,
            fileExplorerObjectUrl: undefined,
            messages: msgRes.data,
            page: 1,
            pagination: msgRes.meta && msgRes.meta.pagination,
            state: true,
            talk,
            talkId
          })
        })
    } catch (e) {
      commit('setError', {
        title: e.message,
        message: e.response && e.response.data && e.response.data.message
      }, { root: true })
    }
  },
  store ({ commit, dispatch, state }, { talkId, message }) {
    const url = `${prepareUrl(uri.support.messages, { id: talkId })}`
    // send message
    return this.$axios.$post(url, message)
      .then(() => {
        dispatch('load', { talkId })
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  delete ({ commit, state }, { talkId, messageId }) {
    commit('delete', { talkId, messageId })
    const url = `${prepareUrl(uri.support.messagesDelete, { id: talkId, messageId })}`
    return this.$axios.$delete(url)
      .then(() => {})
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  read ({ commit }, talkId) {
    const url = `${prepareUrl(uri.support.read, { id: talkId })}`
    return this.$axios.$post(url)
      .then(() => {})
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  toggle ({ commit }, talkId) {
    commit('toggle', talkId)
  },
  lift ({ commit }, talkId) {
    commit('lift', talkId)
  },
  flush ({ commit }, talkId) {
    commit('flush', talkId)
  },
  onFileExplorerClosed ({ commit }, talkId) {
    commit('onFileExplorerClosed', talkId)
  },
  onFileExplorerOpen ({ commit }, talkId) {
    commit('onFileExplorerOpen', talkId)
  },
  setFileExplorerState ({ commit }, { talkId, value }) {
    commit('setFileExplorerState', { talkId, value })
  },
  setFileExplorerObjectUrl ({ commit }, { talkId, value }) {
    commit('setFileExplorerObjectUrl', { talkId, value })
  },
  // TalksList
  loadTalks ({ commit, state }, page) {
    const nextPage = page || 1
    const url = `${uri.support.index}?order[by]=last_message_sent&order[dir]=DESC&page=${nextPage}&type=${state.listType}`
    // get data
    return this.$axios.$get(url).then((response) => {
      if (!state.listPagination) {
        commit('flushTalks')
      }
      log('[Load Talks]', { count: response?.data?.length })
      response?.data.forEach((talk) => {
        commit('load', {
          fileExplorerState: false,
          fileExplorerObjectUrl: undefined,
          messages: [],
          page: 1,
          pagination: {
            current_page: 1,
            per_page: 20,
            count: talk.messages_count,
            total_pages: Math.ceil(talk.messages_count / 20)
          },
          state: false,
          lift: 0,
          talk,
          talkId: talk.id
        })
      })
      commit('listPagination', response?.meta?.pagination)
    }).catch((e) => {
      commit('setError', {
        title: e.message,
        message: e?.response?.data?.message
      }, { root: true })
    })
  },
  flushTalks ({ commit }) {
    commit('flushTalks')
  },
  setTalksType ({ commit, dispatch }, type) {
    commit('setTalksType', type)
  },
  updateMessageList ({ commit }, message) {
    commit('updateMessageList', message)
  },
  updateTalksList ({ commit }, talk) {
    commit('updateTalksList', talk)
  },
  showTalksList ({ commit }) {
    commit('setTalksListState', true)
  },
  hideTalksList ({ commit }) {
    commit('setTalksListState', false)
  }
}
export const mutations = {
  updateField,
  load (state, value) {
    const idx = state.list.findIndex(i => i.talkId === value.talkId)
    if (idx >= 0) {
      if (
        (value.page >= 0 && value.pagination) ||
        (value.page !== 'undefined' && !state.list[idx].pagination)
      ) {
        state.list[idx].pagination = value.pagination
        state.list[idx].page = value.page
      }
      state.list[idx].messages = state.list[idx].messages.concat(value.messages)
      state.list[idx].messages = [
        // map distinct message objects
        ...new Map(state.list[idx].messages.map(i => ([i.id, i]))).values()
      ]
        .sort((a, b) => (a.id > b.id ? -1 : 1))
      if (value.talk) {
        state.list[idx].talk = value.talk
      }
    } else {
      state.list.push(value)
    }
    // sort list by last message
    state.list.sort((a, b) => (moment(a.talk.last_message_sent).isAfter(b.talk.last_message_sent) ? -1 : 1))
  },
  listPagination (state, value) {
    if (!state.listPagination || value.current_page !== 1) {
      state.listPagination = value
    }
  },
  delete (state, { talkId, messageId }) {
    const chatIdx = state.list.findIndex(i => i.talkId === talkId)
    if (chatIdx >= 0) {
      const chat = state.list[chatIdx]
      const messageIdx = chat.messages.findIndex(m => m.id === messageId)
      if (messageIdx >= 0) {
        const message = chat.messages[messageIdx]
        message.deleted_admin = true
      }
    }
  },
  toggle (state, talkId) {
    const chatIdx = state.list.findIndex(i => i.talkId === talkId)
    state.list[chatIdx].state = !state.list[chatIdx].state
  },
  lift (state, talkId) {
    const chatIdx = state.list.findIndex(i => i.talkId === talkId)
    const maxLifted = Math.max(...state.list.filter(i => i.state).map(i => parseInt(i.lift)))
    if (state.list[chatIdx] && parseInt(state.list[chatIdx].lift) <= maxLifted) {
      state.list[chatIdx].lift = maxLifted + 1
    }
  },
  flush (state, value) {
    state.list = state.list.filter(i => i.talkId !== value)
  },
  onFileExplorerClosed (state, talkId) {
    const chat = state.list.find(i => i.talkId === talkId)
    if (chat) {
      chat.fileExplorerState = false
      chat.fileExplorerObjectUrl = undefined
    }
  },
  onFileExplorerOpen (state, talkId) {
    const chat = state.list.find(i => i.talkId === talkId)
    if (chat) {
      chat.fileExplorerState = true
    }
  },
  setFileExplorerState (state, { talkId, value }) {
    const chat = state.list.find(i => i.talkId === talkId)
    if (chat) {
      chat.fileExplorerState = value
    }
  },
  setFileExplorerObjectUrl (state, { talkId, value }) {
    const chat = state.list.find(i => i.talkId === talkId)
    if (chat) {
      chat.fileExplorerObjectUrl = value
    }
  },
  // TalksList
  setTalksListState (state, value) {
    state.talksListState = !!value
  },
  updateMessageList (state, message) {
    if (state.list.length && state.list.map(i => parseInt(i.talkId)).includes(parseInt(message.talk_id))) {
      const idx = state.list.map(i => i.talkId).findIndex(i => parseInt(i) === parseInt(message.talk_id))
      if (idx >= 0) {
        const talk = state.list[idx]
        // log('[updateMessageList]', parseInt(message.talk_id), state.list.map(i => i.talkId), idx, talk)
        talk.messages.push(message)
        talk.messages = [
          // map distinct message objects
          ...new Map(talk.messages.map(i => ([i.id, i]))).values()
        ]
          .sort((a, b) => (a.id > b.id ? -1 : 1))
        state.list[idx] = talk
      }
    }
  },
  updateTalksList (state, talk) {
    log('[Talk Is Opened]', state.list.length && state.list.map(i => parseInt(i.talkId)).includes(parseInt(talk.id)))
    if (state.list.length && state.list.map(i => parseInt(i.talkId)).includes(parseInt(talk.id))) {
      const idx = state.list.findIndex(i => parseInt(i.talkId) === parseInt(talk.id))
      if (idx >= 0) {
        state.list[idx].talk = Object.assign(state.list[idx].talk, talk)
      }
    }
  },
  setTalksType (state, type) {
    if (state.listType !== type) {
      state.listType = type
      state.listPagination = null
    }
  },
  flushTalks (state) {
    state.list = []
  }
}
export const getters = {
  getField,
  getChat: state => (talkId) => {
    return state.list.find(item => item.talkId === talkId)
  },
  getChatOpen: state => (talkId) => {
    return state.list.filter(i => i.state).find(item => item.talkId === talkId)
  },
  isChatInTalksList: state => (talkId) => {
    return !!state.list.find(item => item.talk.id === talkId)
  },
  isChatUnread: state => (talkId) => {
    const chat = state.list.find(item => item.talkId === talkId)
    return chat?.talk?.messages_unread_count > 0 || false
  },
  // TalksList
  talksListState: state => state.talksListState,
  talksListCount: state => state.list.length,
  talksListUnread: state => state.list.map(i => i.talk).reduce((a, b) => a + b.messages_unread_count, 0),
  talksList: state => state.list.map(i => i.talk),
  list: state => state.list,
  nextListPage: state => !state.listPagination || state.listPagination?.current_page < state.listPagination?.total_pages
    ? parseInt(state.listPagination?.current_page) + 1
    : parseInt(state.listPagination?.current_page),
  totalListPages: state => state.listPagination?.total_pages || 0,
  totalListItems: state => state.listPagination?.total || 0,
  listPageSize: state => state.listPagination?.per_page || 0,
  listType: state => state.listType,
  lift (state) {
    return Math.max(...state.list.filter(i => i.state).map(i => parseInt(i.lift)), 0)
  }
}
