import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'

export const initState = {
  list: [],
  tabKeys: [],
  tabId: null
}

export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async loadTabs ({ commit }) {
    if (typeof this.$getTabKeys === 'function') {
      await this.$getTabKeys()
        ?.then(r => commit('setTabKeys', r))
        ?.catch(e => console.error(e))
    }
  },
  async loadTabLogs ({ state, commit, dispatch }, props) {
    if (typeof this.$getLog === 'function') {
      const info = state.tabKeys.find(i => i.tab === props?.tabId)
      if (info) {
        props.openAt = info.open_at
        props.lastActiveAt = info.last_active_at
      }
      await this.$getLog(props)
        ?.then(r => commit('setList', r))
        ?.catch(e => console.error(e))
    }
  },
  async clearLog ({ commit }) {
    if (typeof this.$clearLog === 'function') {
      await this.$clearLog()
        ?.then(() => {
          commit('setTabKeys', [])
          commit('setList', [])
        })
        ?.catch(e => console.error(e))
    }
  }
}

export const mutations = {
  updateField,
  setTabId (state, value) {
    state.tabId = value
  },
  setTabKeys (state, value) {
    state.tabKeys = value.filter((i) => {
      return !i?.title?.match(/Debug/gi)
    })
  },
  setList (state, value) {
    state.list = value
  }
}

export const getters = {
  getField,
  tabs: (state, getters, rootState) => {
    const active = rootState?.shared?.instances ? Object.keys(rootState.shared.instances) : []
    return state.tabKeys.map((k) => {
      return {
        key: k.key,
        title: k.title,
        open_at: k?.open_at ? moment(k.open_at).format('YYYY-MM-DD HH:mm:ss') : null,
        last_active_at: k?.last_active_at ? moment(k.last_active_at).format('YYYY-MM-DD HH:mm:ss') : null,
        count: k?.count || 0,
        is_active: active.includes(k.key)
      }
    })
  },
  getList: state => (tabId = '') => {
    if (tabId !== '') {
      return state.list.find(item => item.tab === tabId)
    } else {
      return state.list
    }
  },
  getTabInfo: state => (tabId) => {
    return state.tabKeys.find(item => item.tab === tabId)
  },
  json: (state) => {
    return JSON.stringify({
      tabs: state.tabKeys,
      logs: state.list
    })
  }
}
